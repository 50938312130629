import React from 'react';
import {
  Container,
  Button,
  Card,
  Row,
  Col,
  Jumbotron,
} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import SocialLinks from './SocialLinks';
import DisplayBoard from '../common/DisplayBoard';
import * as ProfilePic from '../assets/profile-pic.jpg';
import './styles/Summary.css';

const getItems = () => {
  const wishes = [ 
    ['Hello!', 'English'],
    ['नमस्ते!', 'Hindi'],
    ['Hola!', 'Spanish'],
    ['Cześć!', 'Polish'],
    ['Ciao!', 'Italian'],
    ['Bonjour!', 'French'],
    ['!مرحبا', 'Arabic'],
    ['嗨!', 'Chinese'],
    ['こんにちは!', 'Japanese'],
    ['Привет!', 'Russian'],
  ];

  return wishes.map(wish => (
    <p>
      <span className="hello-text">{wish[0]}</span>
      &nbsp;&nbsp;<sup className="hello-language">[{wish[1]}]</sup>
    </p>
  ));
}

const Summary = () => (
  <Container className="pb-5">
    <Row>
      <Col xs={6} md={9} className="p-0">
        <Jumbotron className="bg-light">
          <DisplayBoard items={getItems()}/>
          <p>
            I am a professional computer software engineer, inspired by the art of computing and mathematics. I strive to 
            seek knowledge and make use of it to create things that can help us push the boundaries of technology a little more.
            As a strong minded person, driven by passion for science and technology, I seek a more simplistic approach to understanding the 
            problems and derive solutions which make life easier. This being one side of me, I am an environmental consious, 
            nature loving and spiritual person as the other side of me.

          </p>
          <br/><br/><br/><br/>
          <p>
            <Link to="/career">
              <Button variant="primary">Know More</Button>
            </Link>
          </p>
        </Jumbotron>
      </Col>
      <Col xs={6} md={3}>
        <Card style={{ width: 'auto' }}  className="bg-light">
          <Card.Img variant="top" src={ProfilePic} />
          <Card.Body>
            <Card.Title>CHANDRA SHEKAR CHENNAMSETTY</Card.Title>
            <Card.Text>
              Computer Software Engineer and Technology Enthusiast.
            </Card.Text>
            <SocialLinks />
          </Card.Body>
        </Card>
      </Col>
    </Row>
    {/* <Row className="bg-danger pt-0">
      <Col className="bg-light pt-3 pb-3">
        hellow
      </Col>
    </Row>       */}
  </Container>
);

export default Summary;
