const details = [
  {
    type: 'profession',
    title: 'Senior Software Engineer',
    subtitle: 'Autodesk Inc, San Francisco, CA, USA',
    summary: '',
    date: 'Oct 2015 - Current',
  },
  {
    type: 'profession',
    title: 'Programmer Analyst',
    subtitle: 'Nationwide Insurance, Columbus, Ohio, USA',
    summary: '',
    date: 'Jan 2015 - Oct 2015',
  },
  {
    type: 'profession',
    title: 'Senior Software Engineer',
    subtitle: 'Portware India Pvt Ltd, Hyderabad, India',
    summary: '',
    date: 'June 2013 - Nov 2014',
  },
  {
    type: 'profession',
    title: 'Application Developer',
    subtitle: 'JPMorgan Chase, Hyderabad, India',
    summary: '',
    date: 'Feb 2011 - Feb 2013',
  },
  {
    type: 'profession',
    title: 'Applications Engineer',
    subtitle: 'Oracle India Pvt Ltd, Hyderabad, India',
    summary: '',
    date: 'Sep 2007 - Feb 2011',
  },
  {
    type: 'profession',
    title: 'Systems Analyst',
    subtitle: 'Sonata Software Ltd, Hyderabad, India',
    summary: '',
    date: 'July 2006 - Sep 2007',
  },
  {
    type: 'education',
    title: 'Bachelor of Technology',
    subtitle: 'Jawaharlal Nehru Technological University, Hyderabad, India',
    summary: 'Computer Science & Information Technology',
    date: '2002 - 2006',
  }
];

module.exports = details;