import Summary from './Summary';
import Career from './Career';
import DesignAndArchitecture from './experience/DesignAndArchitecture';
import Coding from './experience/Coding';
import Testing from './experience/Testing';
import OtherExperiences from './experience/Others';
import Contributions from './Contributions';
import Contact from './Contact';
import AlexaPrivacyPolicy from './AlexaPrivacyPolicy';
import AlexaTermsOfUse from './AlexaTermsOfUse';

const Sections = { 
  Summary,
  Career,
  DesignAndArchitecture,
  Coding,
  Testing,
  OtherExperiences,
  Contributions,
  Contact,
  AlexaPrivacyPolicy,
  AlexaTermsOfUse,
};

export default Sections;