import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import './styles/Contact.css';
import SocialLinks from './SocialLinks';

const Contact = () => (
  <Container className="contact-page">
  <Container className="postcard">
    <Container className="bg-light postcard-content">
    <Container className="postcard twist-card">
    <Container className="bg-light pt-3 pb-3 postcard-content">
      <Row className="post-header-area">
        <Col>
          <p className="header-text">Get in touch</p>
        </Col>
        <Col className="stamp-area">
          <img className="stamp" src="https://www.modernpostcard.com/wp-content/uploads/blog/2016/stamp-mpnews.png" alt="Stamp" width="200px"/>
          <img className="stamp-impression" src="https://www.oneimmigrationsolutions.com/wp-content/uploads/stamp.png" alt="Stamp"/>
        </Col>
      </Row>
      <Row className="pt-5 pl-md-5">
        <Col>
          <h5>
            Lets catch up if you think my skills can help you or your organization acheive its goals.
            I would be delighted to share more about me and look forward to work with you.
          </h5>
          <p>
            I am currently located in San Francisco, CA<br/>
            +1 (248) 885-2806 | chandrashekar.ch@gmail.com
          </p>
          <SocialLinks />
          <br/><br/><br/><br/><br/>
        </Col>
      </Row>
    </Container>
  </Container>      
    </Container>
  </Container>

  </Container>
);
export default Contact;
