import React from 'react';
import {
  Row,
  Col,
  Image,
} from 'react-bootstrap';
import '../../node_modules/hover.css/css/hover.css';
import FacebookLogo from '../assets/facebook-logo.png';
import TwitterLogo from '../assets/twitter-logo.png';
import GithubLogo from '../assets/github-logo.png';
import LinkedInLogo from '../assets/linkedin-logo.png';

const SocialLinks = () => (
  <React.Fragment>
  <Row style={{maxWidth: "250px", maxHeight: "50px"}}>
    <Col xs={3}className="text-center p-2">
      <a className="hvr-float-shadow" href="https://www.facebook.com/chennamsetty10" target="_blank" rel="noopener noreferrer">
        <Image src={FacebookLogo} fluid/>
      </a>            
    </Col>
    <Col xs={3} className="text-center p-2">
      <a className="hvr-float-shadow" href="https://twitter.com/csn25" target="_blank" rel="noopener noreferrer">
        <Image src={TwitterLogo} fluid/>
      </a>            
    </Col>
    <Col xs={3} className="text-center p-2">
      <a className="hvr-float-shadow" href="https://github.com/chandu1310"target="_blank" rel="noopener noreferrer">
        <Image src={GithubLogo} fluid/>
      </a>            
    </Col>
    <Col xs={3} className="text-center p-2">
      <a className="hvr-float-shadow" href="https://www.linkedin.com/in/chchandrashekar" target="_blank" rel="noopener noreferrer">
        <Image src={LinkedInLogo} fluid/>
      </a>            
    </Col>
  </Row>
  </React.Fragment>
);

export default SocialLinks;