import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
} from 'react-bootstrap';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import Sections from './sections';
import AppAlert from './common/AppAlert';
import './App.css';
import LogoImage from './assets/logo.png';

const { 
  Summary,
  Career,
  DesignAndArchitecture,
  Coding,
  Testing,
  OtherExperiences,
  Contributions,
  Contact,
  AlexaPrivacyPolicy,
  AlexaTermsOfUse,
} = Sections;

const withTransition = (comp) => () => (
  <ReactCSSTransitionGroup
  transitionAppear={true}
  transitionAppearTimeout={600}
  transitionEnterTimeout={600}
  transitionLeaveTimeout={200}
  transitionName='loadComponent'
  >
    {comp}
  </ReactCSSTransitionGroup>
);

const Demo = () => (
  <div className="app">
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top">
      <Container>
        <Navbar.Brand as={NavLink} to='/' style={{color:"#D4AF37"}}>
          <img
            width={18}
            height={18}
            className="mb-1"
            src={LogoImage}
            alt="CH"
          /> H A N D R A S H E K A R . C H</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-header-navbar-nav" />
        <Navbar.Collapse id="responsive-header-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={NavLink} to='/summary'>Summary</Nav.Link>
            <Nav.Link as={NavLink} to='/career'>Career</Nav.Link>
            <NavDropdown title='Experience' id='collasible-nav-dropdown'>
              <NavDropdown.Item as={NavLink} to='/designandarchitecture'>Design/Architecture</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to='/coding'>Coding</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to='/testing'>Testing</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={NavLink} to='/others'>Others</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to='/contributions'>Projects</Nav.Link>
            <Nav.Link as={NavLink} to='/contact'>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <AppAlert />
    <Switch>
      <Route exact path="/">
        <Redirect from="/" exact to="/summary" />
      </Route>
      <Route path='/summary' component={withTransition(<Summary />)}/>
      <Route path='/career' component={withTransition(<Career />)}/>
      <Route path='/designandarchitecture' component={withTransition(<DesignAndArchitecture />)}/>
      <Route path='/coding' component={withTransition(<Coding />)}/>
      <Route path='/testing' component={withTransition(<Testing />)}/>
      <Route path='/others' component={withTransition(<OtherExperiences />)}/>
      <Route path='/contributions' component={withTransition(<Contributions />)}/>
      <Route path='/contact' component={withTransition(<Contact />)}/>
      <Route path='/alexa-covidspy-privacypolicy' component={withTransition(<AlexaPrivacyPolicy />)}/>
      <Route path='/alexa-covidspy-termsofuse' component={withTransition(<AlexaTermsOfUse />)}/>
      <Route component={withTransition(<Summary />)}/>
    </Switch>
     <Navbar collapseOnSelect expand="xl" bg="light" variant="light" fixed="bottom" className="footerbackgrund">
      <Container>
        <Navbar.Brand as={NavLink} to='/summary'>
          <img
            src={LogoImage}
            width={32}
            height={32}
            alt="CH"
          /> 
        </Navbar.Brand>
        <p style={{ color: 'lightgray', fontSize: '12px', fontWeight: 'bold', width: '200px'}}>
          <br/>
          Everything on this web page is handcoded and is solely designed and created by me.
          All rights reserverd.
        </p>
        <Navbar.Toggle aria-controls="responsive-footer-navbar-nav" />
        <Navbar.Collapse id="responsive-footer-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={NavLink} to='/summary'>Home</Nav.Link>
            <Nav.Link as={NavLink} to='/contact'>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </div>
);

class App extends Component {
  render() {
    return (<Demo />);
  }
}

export default App;
