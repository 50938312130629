import React from 'react';
import {
  Container,
} from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SVGIcon from '../common/SVGIcon';
import CareerDetails from './career-details';

const SchoolIcon = <SVGIcon name="SchoolIcon" />
const SchoolIconStyle = { background: 'rgb(233, 30, 99)', color: '#fff' };
const WorkIcon = <SVGIcon name="WorkIcon" />
const WorkIconStyle = { background: 'rgb(33, 150, 243)', color: '#fff' };
const StarIcon = <SVGIcon name="StarIcon" />

const createElement = (details) => {
  const { type, title, subtitle, summary, date } = details;
  const icon = (type === 'education' ? SchoolIcon : WorkIcon);
  const iconStyle = (type === 'education' ? SchoolIconStyle : WorkIconStyle);
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element"
      date={date}
      iconStyle={iconStyle}
      icon={icon}
    >
      <h3 className="vertical-timeline-element-title">
        {title}
      </h3>
      <h5 className="vertical-timeline-element-subtitle">
        {subtitle}
      </h5>
      <p>
        {summary}
      </p>
    </VerticalTimelineElement>
  );
}

const Education = () => (
  <Container className="bg-light pt-3 pb-5 mb-5">
    <h1>Career</h1>
    <hr/>
    <p>
      With an overall experience of about 12 Years in IT industry, I am proud to be associated with many companies where I contributed as a developer, 
      woking on some of the curcial business applications. Starting as a Java Developer, I gained experience and expertise on working with both backend 
      and frontend systems using various new frameworks and tools. I devled in cloud computing and distributed systems, since a very early stage in my career and 
      currently have strong grip on implementing end to end solutions on platforms like Amazon AWS. My primary coding languages are Java and Javascript.
    </p>
    <VerticalTimeline>
      {
        CareerDetails.map(createElement)
      }
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={StarIcon}
      />
    </VerticalTimeline>
  </Container>
);

export default Education;