import React from 'react';
import {
  Container,
} from 'react-bootstrap';

const Testing = () => (
  <Container className="bg-light pt-3 pb-5">
    <h1>Testing</h1>
    <hr/>
    <p className="pb-5">
      As I worked on a wide spread of technologies, testing the code written was of utmost importance.
      It was more of a habit then practice to plan the test scenarios, cases, and the data required way before implementation. This approach of building code based in tandem with the tests helped me deliver quality code with least scope for refactoring to cover missed scenarios or to accommodate an unknown bug fix. The code also reflects quality and is self certified with well documented and covered tests. A detailed test report related to code coverage also adds up. Most of experience called for understanding, creating and enhancing tests written for unit testing, integration testing, visual regression testing, and also full functional or end-to-end testing of workflows created.
      A cross platform compatibility check using Sauce labs was also my expertise. 
      <br/><br/>
      
      <h3>Technology:</h3>
      In order to achieve a good level confidence, every module built was tagged with equally complex tests.
      This automated validation was key step in all the projects done at Autodesk.
      <br/>
      <ul>
        <li>For Java, the JUnit4 Framework facilitated this, in addition to Mockito.</li>
        <li>For NodeJS, i used sinon and chai working together to test all parts of the code.</li>
        <li>For ReactJS, i went with Jest and Jasmine.</li>
      </ul>
      <br/>
      Apart from these, the following test strategies were some interesting aspects of my work.
      <ul>
        <li>Visual Regression  - WebdriverIO,Sauce Labs, Docker, Storybook</li>
        <li>Functional Testing - Webdriver IO, mockserver, Docker</li>
      </ul>
    </p>
  </Container>
);

export default Testing;