import React from 'react';
import {
  Container,
  Row,
  Col,
  Media,
  Nav,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';
import Axios from 'axios';
import GitHeaderImage from '../assets/github-header-logo.png';
import GitImage from '../assets/github-logo-medium.png';

const LoadingComponent = () => (
  <Media>
    <Skeleton circle={true} height={64} width={64} />
    <Media.Body className="pl-2">
      <Skeleton width={150}/>
      <p className="w-100">
        <Skeleton count={2}/>
      </p>
    </Media.Body>
  </Media>
);

const GitRepoDetailsComponent = ({ name, description, link }) => (
  <Media>
    <img
      width={64}
      height={64}
      className="mr-3 "
      src={GitImage}
      alt="Github"
    />
    <Media.Body>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <h5>{name}</h5>
      </a>
      <p>
        {description}
      </p>
    </Media.Body>
  </Media>
);

class Contributions extends React.Component {
  constructor() {
    super();
    this.state = { gitrepos: [] }
  }

  componentDidMount() {
    Axios.get('https://api.github.com/users/chandu1310/starred').then(response => {
      setTimeout(() => this.setState({ gitrepos: response.data }), 2000);
    });
  }

  render() {
    const { gitrepos } = this.state;
    let GitRepos = [<Row><Col><LoadingComponent /></Col></Row>, <Row><Col><LoadingComponent /></Col></Row>, <Row><Col><LoadingComponent /></Col></Row>];
    if (gitrepos.length !== 0) {
      GitRepos = gitrepos.map(repo => (
        <Row className="pb-3" key={repo.name}>
          <Col>
            <GitRepoDetailsComponent name={repo.name} description={repo.description} link={repo.html_url} />
          </Col>
        </Row>
      ));
    }

    return (
    <Container className="bg-light pt-3 pb-5 mb-5">
      <h1>Contributions</h1>
      <hr/>
      <Container className="pt-3 pb-5">
        <Row>
          <Col className="pt-2" md={5} >
            As once Albert Einstein said, <br/><br/>
            <p>
              <q><i>Make everything as simple as possible, but not simpler.</i></q>
            </p>
            <p>
              Much inspired by his works and his intelligence, I always strived to give back to the community.
              Some of the open source contributions very much show my belief in his words about creating code which made solutions possible more easily.
            </p>
            <p>
              Most my work around nodejs and java programming are readily available on github.com and npmjs.org. List here are some of the noted repos i created on github.
            </p>
            <p>
              <Nav.Link as={NavLink} to='/contact'>Contact</Nav.Link>
            </p>
          </Col>
          <Col>
            <Row className="pb-1">
              <Col>
                <h4>
                  <img
                    width={195}
                    height={75}
                    className="mb-3"
                    src={GitHeaderImage}
                    alt="Github"
                  />
                </h4>
              </Col>
            </Row>
            {GitRepos}          
          </Col>
        </Row>
      </Container>
    </Container>
    );
  }
}

export default Contributions;