import React from 'react';
import Fader from 'react-fader';

class DisplayBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items.length && props.items.length > 0 ? props.items : [ <h1>Hello</h1> ],
      selected: 0,
    };
  }

  updateText() {
    const items = this.state.items;
    const selected =  (this.state.selected + 1) % items.length; //Math.floor(Math.random() * items.length); 
    this.setState({ selected });
  }

  componentDidMount() {
    if(this.state.items.length > 0)
      setInterval(() => this.updateText(), 2000);
  }

  render() {
    const returnValue = this.state.items.length === 1 ? this.state.items[this.state.selected] : (
      <Fader fadeOutTransitionDuration="300">
        {this.state.items[this.state.selected]}
      </Fader>
    )
    return returnValue;
  }
}

export default DisplayBoard;