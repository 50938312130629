import React from 'react';
import {
  Container,
} from 'react-bootstrap';

const DesignAndArchitecture = () => (
  <Container className="bg-light pt-3 pb-3">
    <h1>Design & Architecture</h1>
    <hr/>
  </Container>
);

export default DesignAndArchitecture;