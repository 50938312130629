import React from 'react';
import {
  Row,
  Col,
} from 'react-bootstrap';
import '../../node_modules/hover.css/css/hover.css';

const AlexaTermsOfUse = () => (
  <React.Fragment>
  <Row>
    <Col>
      <div style={{maxWidth: "95%", textAlign: "center"}}>
        <h2>Terms of Use for Alexa Skills</h2>
        <br/>
        <h3>General</h3>
        This Terms of Use are an agreement between you (the „user”) and publisher (Chandra Shekar Chennamsetty) (“us” or “we”).
        <br/>
        Before using any of our Alexa Skills (“our skills”), please read this agreement and our <a href="/alexa-covidspy-privacypolicy">Alexa Skills Privacy Policy</a> as well as the <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201809740">Alexa Terms of Use</a> by the Amazon Digital Services LLC (with its affiliates, “Amazon”).
        <br/>
        Regarding Amazon, “Alexa” means their Alexa Voice Service which includes third party services (like our skills) and other related Software.
        <br/>
        If you use one of our skills you fully agree to this Terms of Use.
        
        <br/><br/>
        <h3>Disclaimer</h3> 
        Our skills or parts of it may discontinue at any time or might contain errors. 
        <br/>
        You use our skills on your own responsibility. Any information should not be seen as an advice.
        
        <br/><br/>
        <h3>Changes</h3>
        Our skills or parts of it may change or be updated at any time. 
        <br/>
        Further, our Terms of Use might change. You can find a link to this agreement on the description page of the skill in the Alexa App or in the Alexa Store. 
        <br/>
        Your continued use of our skill after changes of the Terms of Use or the skill itself will be seen as your acceptance of both.
        
        <br/><br/>
        <h3>For You</h3>
        We love developing Alexa Skills for you. And we love to make things better by developing digital products and services.
      </div>
    </Col>
  </Row>
  </React.Fragment>
);

export default AlexaTermsOfUse;