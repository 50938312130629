import React from 'react';
import {
  Container,
  // Alert,
  // Button,
  // Collapse,
} from 'react-bootstrap';

export default class AppAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  render() {
    // const handleDismiss = () => this.setState({ show: false });
    // const handleShow = () => this.setState({ show: true });
    return (
      <Container className="p-0 pt-2">
        {/* <Alert variant="warning" onClose={handleDismiss} dismissible show={this.state.show}>
          <Alert.Heading>Hey, nice to see you</Alert.Heading>
          <p>
          This is my personal website and I intend to provide more details about me soon.
          I appreciate your time and effor to visit this site looking for me.
          Please visit again as I work and finish building this website.
          </p>
        </Alert> */}
      </Container>
    );
  }
}
