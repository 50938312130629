import React from 'react';
import {
  Container,
} from 'react-bootstrap';

const OtherExperiences = () => (
  <Container className="bg-light pt-3 pb-3">
    <h1>Other Experiences</h1>
    <hr/>
  </Container>
);

export default OtherExperiences;