import React from 'react';
import {
  Row,
  Col,
} from 'react-bootstrap';
import '../../node_modules/hover.css/css/hover.css';

const AlexaPrivacyPolicy = () => (
  <React.Fragment>
  <Row>
    <Col>
      <div style={{maxWidth: "95%", paddingLeft: "20px"}}>
        <h2>Alexa Skill Privacy Policy</h2>
        <ol>
          <li>
            This agreement governs your use of the Alexa skill on an Alexa enabled device, like Amazon Echo (the ‘Skill’).
          </li>
          <li>
            By linking the Skill, you agree to the following terms and conditions. These should be read in conjunction with the common sense.
          </li>
          <li>
            This skill doesnt require any personal information from you except the answers for responses to action choices.
          </li>
          <li>
            We do not need any login or account linking for using this skill. Just enable and use the skill as it is.
          </li>
          <li>
            We endeavour to make sure the information we provide is correct but you should not rely on any of the information.
            <br/>
            We accept no responsibility for any damage, loss, liabilities, injury or disappointment incurred or suffered by you as a result of using the Skill, including as a result of us providing inaccurate, untimely, incomplete, erroneous information.
          </li>
          <li>
            We shall not be liable for any failure to comply with its obligations where the failure is caused by something outside its reasonable control.
          </li>
          <li>
            The publisher/developer of the skill reserves the right to modify or discontinue the Skill at any time without prior notice.
          </li>
          <li>
            Should you have any questions about the Skill please contact us at stayfoolishforever@gmail.com
          </li>
        </ol>        
      </div>
    </Col>
  </Row>
  </React.Fragment>
);

export default AlexaPrivacyPolicy;